import React, {useState, useEffect} from 'react';
import Nunox from '../../../svg/nunox.svg';
import FooterMain from './FooterMain/FooterMain';

const Footer = ({ footer, dataFooter, dataServices}) => {

  const [date , setDate] = useState();

  const getYear = () =>  setDate(new Date().getFullYear())

  useEffect(() => { getYear();}, [])
  
  return (
    <footer className="footer" data-scroll-section>
      <div className="container-fluid">
        <FooterMain 
          footer={footer} 
          dataFooter={dataFooter} 
          dataServices={dataServices}
        />
        <div className="footer-bottom">
          <span className="footer-bottom__copyright">{date} &copy; O.X.X. Clinic</span>
          <a
            href="https://nunox.co/"
            target="blank"
            className="footer-bottom__create"
          >
            <Nunox />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
