import React from 'react';

const Burger = ({ openMenu, active, show, activeHeader, whitwHeader }) => {
  return (
    <button
      className={
        active
          ? 'burger is-active'
          : show
          ? 'burger active'
          : activeHeader
          ? 'burger active'
          : whitwHeader === 'white'
          ? 'burger active'
          : 'burger'
      }
      onClick={openMenu}
    >
      <span className="lines">
        <span className="line line-1"></span>
        <span className="line line-2"></span>
        <span className="line line-3"></span>
      </span>
    </button>
  );
};

export default Burger;
