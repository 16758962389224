import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../../Hooks/useLanguage';

const FooterServices = ({ dataServices }) => {
  const langToggle = useLanguage;
  return (
    <ul className="footer-nav">
      {dataServices.map((item, index) => {
        return (
          <li className="footer-nav__item" key={index}>
            <Link to={'/' + item.slug}>
              {langToggle(item.title_ua, item.title_ru, item.title_en)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default FooterServices;
