import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import { scroller } from 'react-scroll';

import FooterSocials from './FooterSocials/FooterSocials';
import FooterContacts from './FooterContacts/FooterContacts';
import FooterServices from './FooterServices/FooterServices';
import FooterNavigation from './FooterNavigation/FooterNavigation';

const FooterMain = ({ footer, dataFooter, dataServices }) => {
  const langToggle = useLanguage;

  function scrollToSection() {
    scroller.scrollTo('scroller-top', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  return (
    <div className="footer-main">
      <div className="footer-main__item item-1">
        <span className="footer-item__title">
          {langToggle('Послуги:', 'Услуги:', 'Services:')}
        </span>
        <FooterServices dataServices={dataServices} />
      </div>
      <div className="footer-main__item item-2">
        <span className="footer-item__title">
          {langToggle('Користувачам:', 'Пользователям:', 'Users:')}
        </span>
        <FooterNavigation footer={footer} />
      </div>
      <div className="footer-main__item item-3">
        <span className="footer-item__title">
          {langToggle('Контакти:', 'Контакты:', 'Contacts:')}
        </span>
        <FooterContacts dataFooter={dataFooter}/>
      </div>
      <div className="footer-main__item item-4">
        <button
          // href="#scroller-top"
          className="scroll-top"
          onClick={scrollToSection}
          data-scroll-to
        >
          <span className="pulse"></span>
          <span className="scroll-top__wrapper">
            <span className="scroll-top__title">
              {langToggle('Вгору', 'Вверх', 'Up')}
            </span>
          </span>
        </button>
        <FooterSocials dataFooter={dataFooter} />
      </div>
    </div>
  );
};

export default FooterMain;
