const dataFooter = {
  footerServices: [
    {
      id: 1,
      title_ua: 'Естетична Медицина',
      title_ru: 'Эстетическая Медицина',
      title_en: 'Aesthetic Medicine',
      link: '/',
      icon: '../../../../../../serv_01.svg',
    },
    {
      id: 2,
      title_ua: 'Косметологія',
      title_ru: 'Косметология',
      title_en: 'Cosmetology',
      link: '/',
      icon: '../../../../../../serv_02.svg',
    },
    {
      id: 3,
      title_ua: 'Дерматологія',
      title_ru: 'Дерматология',
      title_en: 'Dermatology',
      link: '/',
      icon: '../../../../../../serv_03.svg',
    },
    {
      id: 4,
      title_ua: "Ін'єкційні Методики",
      title_ru: 'Инъекционные Методики',
      title_en: 'Injection Techniques',
      link: '/',
      icon: '../../../../../../serv_04.svg',
    },
    {
      id: 5,
      title_ua: 'Апаратна Косметологія',
      title_ru: 'Hardware Cosmetology',
      title_en: 'Injection Techniques',
      link: '/',
      icon: '../../../../../../serv_05.svg',
    },
    {
      id: 6,
      title_ua: 'Гінекологія',
      title_ru: 'Гинекология',
      title_en: 'Gynecology',
      link: '/',
      icon: '../../../../../../serv_06.svg',
    },
    {
      id: 7,
      title_ua: 'Урологія',
      title_ru: 'Урология',
      title_en: 'Urology',
      link: '/',
      icon: '../../../../../../serv_07.svg',
    },
    {
      id: 8,
      title_ua: 'Подологія',
      title_ru: 'Подология',
      title_en: 'Podology',
      link: '/',
      icon: '../../../../../../serv_08.svg',
    },
  ],
  footerNavigation: [
    {
      id: 1,
      title_ua: 'FAQ',
      title_ru: 'FAQ',
      title_en: 'FAQ',
      link: '/faq/',
    },
    {
      id: 2,
      title_ua: 'Угода Користувача',
      title_ru: 'Пользовательское Соглашение',
      title_en: 'Terms of use',
      link: '/',
    },
    {
      id: 3,
      title_ua: 'Політика конфіденційності',
      title_ru: 'Политика конфиденциальности',
      title_en: 'Privacy Policy',
      link: '/',
    },
    {
      id: 4,
      title_ua: 'Документація',
      title_ru: 'Документация',
      title_en: 'Documentation',
      link: '/',
    },
  ],
};

export default dataFooter;
