import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';
import Dot from '../../../../svg/dot.svg';

const Languages = ({ show, activeHeader, whitwHeader }) => {
  const langToggle = useLanguage;
  const { languages, originalPath } = useI18next();

  const [activeItem, setActiveItem] = React.useState(false);

  function moseEnterHendler() {
    setActiveItem(!activeItem);
  }

  function mouseLeaveHendler() {
    setActiveItem(false);
  }

  return (
    <div
      role="presentation"
      className={
        activeItem
          ? 'language-wrapper is-open'
          : show
          ? 'language-wrapper active'
          : activeHeader
          ? 'language-wrapper active'
          : whitwHeader === 'white'
          ? 'language-wrapper active'
          : 'language-wrapper'
      }
      onMouseEnter={moseEnterHendler}
      onMouseLeave={mouseLeaveHendler}
    >
      <span className="language-button__bg"></span>
      <button className="language-button">
        <span className="language-button__titles">
          <span className="language-button__title">
            {langToggle('Ua', 'Ru', 'En')}
          </span>
          <span className="language-button__title">
            <Dot />
          </span>
        </span>
      </button>
      <ul className={!activeItem ? 'languages' : 'languages is-open'}>
        {languages.map((lng) => (
          <li key={lng}>
            <Link to={originalPath} language={lng}>
              {lng}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Languages;
