import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';

import Dot from '../../../../svg/dot.svg';

const TrainigsButton = ({ show, activeHeader, whitwHeader }) => {
  const langToggle = useLanguage;
  return (
    <Link
      className={
        show
          ? 'trainings-button active'
          : activeHeader
          ? 'trainings-button active'
          : whitwHeader === 'white'
          ? 'trainings-button active'
          : 'trainings-button'
      }
      to="/trainings/"
    >
      <span className="trainings-button__bg"></span>
      <span className="trainings-button__titles">
        <span className="titles-wrapper">
          <span className="trainings-button__title">
            {langToggle('Тренінги', 'Тренинги', 'Trainings')}
          </span>
        </span>
      </span>
      <span className="trainings-button__icons">
        <i className="button-icon">
          <Dot />
        </i>
        <i className="button-icon">
          <Dot />
        </i>
      </span>
    </Link>
  );
};

export default TrainigsButton;
