import React from 'react';
import ServicesButton from './ServicesButton/ServicesButton';
import ServicesSlider from './ServicesSlider/ServicesSlider';

const ServicesDesctop = ({
  moseEnterHendler,
  mouseLeaveHendler,
  show,
  activeHeader,
  whitwHeader,
  dataServices
}) => {
  return (
    <div
      className="services-desctop__wrapper"
      role="presentation"
      onMouseEnter={moseEnterHendler}
      onMouseLeave={mouseLeaveHendler}
    >
      <ServicesButton
        show={show}
        activeHeader={activeHeader}
        whitwHeader={whitwHeader}
      />
      <div
        className={
          show ? 'services-desctop__drop active' : 'services-desctop__drop'
        }
      >
        <ServicesSlider show={show} dataServices={dataServices}/>
      </div>
    </div>
  );
};

export default ServicesDesctop;
