import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import Header from './Header/Header';
import Footer from './Footer/Footer';

import dataMenu from './dataMenu';
// import dataServices from '../../db/dataServices';
import dataFooter from './dataFooter';

const Layout = ({ children, whitwHeader }) => {
  const dataNav = dataMenu;
  // const servicesData = dataServices;
  const footer = dataFooter;

  return (
    <StaticQuery
      query={graphql`
        query Header {
          allStrapiServicesCategories {
            nodes {
              title_ua
              title_ru
              title_en
              slug
              icon {
                url
              }
            }
          }
          strapiContacts {
            address_ua
            address_ru
            address_en
            google_maps_link
            instagram
            facebook
            youtube
            tel {
              telephone
            }
            email {
              email
            }
          }
        }
      `}
      render={data => (
    <>
      <Header
        dataServices={data.allStrapiServicesCategories.nodes}
        dataNav={dataNav}
        whitwHeader={whitwHeader}
      />
      <main>{children}</main>
      <Footer 
        footer={footer} 
        dataFooter={data.strapiContacts} 
        dataServices={data.allStrapiServicesCategories.nodes}
        />
    </>
        )}
      />
  );
};

export default Layout;
