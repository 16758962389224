const dataMenu = [
  {
    id: 1,
    type: 'default',
    title_ua: 'Головна',
    title_ru: 'Главная',
    title_en: 'Home',
    link: '/',
  },
  {
    id: 2,
    type: 'services',
    title_ua: 'Послуги',
    title_ru: 'Услуги',
    title_en: 'Services',
    services: [
      {
        id: 1,
        title_ua: 'Естетична Медицина',
        title_ru: 'Эстетическая Медицина',
        title_en: 'Aesthetic Medicine',
        link: '/services-catalog/',
      },
      {
        id: 2,
        title_ua: 'Косметологія',
        title_ru: 'Косметология',
        title_en: 'Cosmetology',
        link: '/services-catalog/',
      },
      {
        id: 3,
        title_ua: 'Дерматологія',
        title_ru: 'Дерматология',
        title_en: 'Dermatology',
        link: '/services-catalog/',
      },
      {
        id: 4,
        title_ua: "Ін'єкційні Методики",
        title_ru: 'Инъекционные Методики',
        title_en: 'Injection Techniques',
        link: '/services-catalog/',
      },
      {
        id: 5,
        title_ua: 'Апаратна Косметологія',
        title_ru: 'Аппаратная косметология ',
        title_en: 'Hardware Cosmetology',
        link: '/services-catalog/',
      },
      {
        id: 6,
        title_ua: 'Гінекологія',
        title_ru: 'Гинекология',
        title_en: 'Gynecology',
        link: '/services-catalog/',
      },
      {
        id: 7,
        title_ua: 'Урологія',
        title_ru: 'Урология',
        title_en: 'Urology',
        link: '/services-catalog/',
      },
      {
        id: 8,
        title_ua: 'Подологія',
        title_ru: 'Подология',
        title_en: 'Podology',
        link: '/services-catalog/',
      },
    ],
  },
  {
    id: 3,
    title_ua: 'Ціни',
    title_ru: 'Цены',
    title_en: 'Prices',
    link: '/prices/',
  },
  {
    id: 4,
    title_ua: 'Тренінги',
    title_ru: 'Тренинги',
    title_en: 'Training',
    link: '/trainings/',
  },
  {
    id: 5,
    title_ua: 'Про клініку',
    title_ru: 'О клинике',
    title_en: 'About clinic',
    link: '/about/',
  },
  {
    id: 6,
    title_ua: 'Статті',
    title_ru: 'Статьи',
    title_en: 'Articles',
    link: '/articles/',
  },
  {
    id: 7,
    title_ua: 'Контакти',
    title_ru: 'Контакты',
    title_en: 'Contacts',
    link: '/contacts/',
  },
];

export default dataMenu;
