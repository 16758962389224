import React from 'react';
import MainMenu from './MainMenu/MainMenu';
import SubMenu from './SubMenu/SubMenu';

const Navigation = ({
  dataNav,
  dataServices,
  closeMenu,
  active,
  openSubMenu,
  closeSubMenu,
  subMenu,
}) => {
  return (
    <div
      className={active ? 'navigation-wrapper is-open' : 'navigation-wrapper'}
    >
      <div
        className="navigation-owerlay"
        onClick={closeMenu}
        role="presentation"
      ></div>

      <button className="navigation-close" onClick={closeMenu}>
        x
        <span className="lines">
          <div className="line line-1"></div>
          <div className="line line-2"></div>
        </span>
      </button>

      <MainMenu dataNav={dataNav} openSubMenu={openSubMenu} active={active} />
      <SubMenu
        dataServices={dataServices}
        closeSubMenu={closeSubMenu}
        subMenu={subMenu}
      />
    </div>
  );
};

export default Navigation;
