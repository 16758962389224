import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useScroll from '../../Hooks/useScroll';
// import useWheel from '../../Hooks/useWheel';

import Navigation from './Navigation/Navigation';
import Languages from './Languages/Languages';
import BookOnline from './BookOnline/BookOnline';
import Burger from './Burger/Burger';

import Logo from '../../../svg/logo.svg';
import TrainigsButton from './TrainigsButton/TrainigsButton';
import ServicesDesctop from './ServicesDesctop/ServicesDesctop';
import Overlay from './Overlay/Overlay';

const Header = ({ whitwHeader, dataNav, servicesCategory, dataServices}) => {
  const [active, setActive] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [activeHeader, setActiveHeader] = React.useState(false);
  const [subMenu, setSubMenu] = React.useState(false);

  const changePosition = 100;
  let scroller = useScroll();

  if (scroller > changePosition && !activeHeader) {
    setActiveHeader(true);
  }

  if (scroller <= changePosition && activeHeader) {
    setActiveHeader(false);
  }

  const openMenu = () => {
    setActive(!active);
  };

  const closeMenu = () => {
    setActive(false);
    setSubMenu(false);
  };

  const openSubMenu = () => {
    setSubMenu(!subMenu);
  };

  const closeSubMenu = () => {
    setSubMenu(false);
  };

  const moseEnterHendler = () => {
    setShow(!show);
  };

  const mouseLeaveHendler = () => {
    setShow(false);
  };

  return (
    <>
      <header
        className={
          show
            ? 'header show'
            : activeHeader
            ? 'header scroll'
            : whitwHeader === 'white'
            ? 'header header-alternative'
            : 'header'
        }
      >
        <div className="header-wrapper">
          <Link to="/" className="logo">
            <Logo />
          </Link>
          <div className="header-right">
            <Languages
              show={show}
              activeHeader={activeHeader}
              whitwHeader={whitwHeader}
            />
            <ServicesDesctop
              moseEnterHendler={moseEnterHendler}
              mouseLeaveHendler={mouseLeaveHendler}
              show={show}
              activeHeader={activeHeader}
              whitwHeader={whitwHeader}
              servicesCategory={servicesCategory}
              dataServices={dataServices}
            />
            <TrainigsButton
              show={show}
              activeHeader={activeHeader}
              whitwHeader={whitwHeader}
            />
            <BookOnline
              show={show}
              activeHeader={activeHeader}
              whitwHeader={whitwHeader}
            />
            <Burger
              openMenu={openMenu}
              active={active}
              show={show}
              activeHeader={activeHeader}
              whitwHeader={whitwHeader}
            />
          </div>
        </div>
      </header>
      <Navigation
        dataNav={dataNav}
        dataServices={dataServices}
        closeMenu={closeMenu}
        active={active}
        openSubMenu={openSubMenu}
        closeSubMenu={closeSubMenu}
        subMenu={subMenu}
      />
      <Overlay show={show} />
    </>
  );
};

export default Header;
