import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

import Dot from '../../../../../svg/dot.svg';

const ServicesButton = ({ show, activeHeader, whitwHeader }) => {
  const langToggle = useLanguage;

  return (
    <button
      className={
        show
          ? 'services-button active'
          : activeHeader
          ? 'services-button active'
          : whitwHeader === 'white'
          ? 'services-button active'
          : 'services-button'
      }
    >
      {/* <span className="services-button__bg"></span> */}
      <span className="services-button__titles">
        <span className="titles-wrapper">
          <span className="services-button__title">
            {langToggle('Послуги', 'Услуги', 'Services')}
          </span>
        </span>
      </span>
      <span className="services-button__icons">
        <i className="button-icon">
          <Dot />
        </i>
        <i className="button-icon">
          <Dot />
        </i>
      </span>
    </button>
  );
};

export default ServicesButton;
