import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';

import Plus from '../../../../svg/plus.svg';
import PlusBig from '../../../../svg/plus_big.svg';
import Dot from '../../../../svg/dot.svg';

const BookOnline = ({ show, activeHeader, whitwHeader }) => {
  const langToggle = useLanguage;
  return (
    <button
      className={
        show
          ? 'book-online active'
          : activeHeader
          ? 'book-online active'
          : whitwHeader === 'white'
          ? 'book-online active'
          : 'book-online'
      }
    >
      <div className="book-online__bg"></div>
      <div className="btn-wrapper">
        <div className="book-online__titles">
          <div className="titles-wrapper">
            <div className="book-online__title">
              {langToggle('Запис Онлайн', 'Запись Онлайн', 'Online booking')}
            </div>
          </div>
        </div>
        <div className="button-icons">
          <div className="button-icons__wrapper">
            <i className="button-icon">
              <Plus className="plus-svg" />
            </i>
            <i className="button-icon">
              <Dot className="dot-svg" />
            </i>
          </div>
        </div>
        <i className="mobile-icon">
          <PlusBig className="plus-svg__big" />
        </i>
      </div>
    </button>
  );
};

export default BookOnline;
