import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const FooterContacts = ({dataFooter}) => {

  const langToggle = useLanguage;
  return (
    <div className="footer-contacts">
      <div className="footer-contacts__item">
        <span className="footer-contacts__title">
          {langToggle('Телефон:', 'Телефон:', 'Phone:')}
        </span>
        {dataFooter.tel.map((item, i) => {
          return(
          <a
            key={i}
            href={'tel:' + item.telephone}
            className="footer-contacts__link footer-contacts__phone"
          >
            {item.telephone}
          </a>
          )       
        })}       
      </div>
      <div className="footer-contacts__item">
        <span className="footer-contacts__title">E-mail:</span>
        {dataFooter.email.map((mail, i) => {
          return(
        <a
          key={i}
          href={'mailto:' + mail.email}
          className="footer-contacts__link"
        >
          {mail.email}
        </a>
          )  
        })}  
      </div>
      <div className="footer-contacts__item">
        <p className="footer-contacts__adress">
          {langToggle(
            dataFooter.address_ua,
            dataFooter.address_ru,
            dataFooter.address_en    
          )}
        </p>
      </div>
    </div>
  );
};

export default FooterContacts;
