import React from 'react';
import Facebook from '../../../../../svg/facebook.svg';
import Instagram from '../../../../../svg/instagram.svg';
import Youtube from '../../../../../svg/youtube.svg';

const FooterSocials = ({dataFooter}) => {
  return (
    <ul className="socials">
      <li className="socials-item">
        <a
          href={dataFooter.instagram}
          target="blank"
          className="socials-item__link"
        >
          <Instagram />
        </a>
      </li>
      <li className="socials-item">
        <a
          href={dataFooter.facebook}
          target="blank"
          className="socials-item__link"
        >
          <Facebook />
        </a>
      </li>
      <li className="socials-item">
        <a
          href={dataFooter.youtube}
          target="blank"
          className="socials-item__link"
        >
          <Youtube />
        </a>
      </li>
    </ul>
  );
};

export default FooterSocials;
