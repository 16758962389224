import React, { useRef } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import useLanguage from '../../../../Hooks/useLanguage';

import ArrowLeft from '../../../../../svg/arrow_left.svg';
import ArrowRight from '../../../../../svg/arrow_right.svg';

// import dataServices from '../dataServices';

const ServicesSlider = ({ show, dataServices }) => {
  const langToggle = useLanguage;
  const servicesData = dataServices;

  const slider = useRef(null);

  // function sliderGoToPrev() {
  //   slider.slickPrev();
  // }
  // function sliderGoToNext() {
  //   slider.slickNext();
  // }

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className={className}
        className={
          show
            ? 'slider-button slider-button__next show'
            : 'slider-button slider-button__next'
        }
        onClick={onClick}
      >
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className={className}
        className={
          show
            ? 'slider-button slider-button__prev show'
            : 'slider-button slider-button__prev'
        }
        onClick={onClick}
      >
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    //autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
    ],
  };

  return (
    <div className="container-fluid">
      <Slider
        ref={slider}
        {...settings}
        className={show ? 'services-slider active' : 'services-slider'}
      >
        {servicesData.map((item, index) => {
          return (
            <div
              className={
                show
                  ? 'services-slider__slide active'
                  : 'services-slider__slide'
              }
              key={index}
            >
              <div className="slide-wrapper">
                <div className="slide-background"></div>
                <img className="slide-icon" 
                  src={`https://admin.oxx-clinic.com.ua${item.icon.url}`} 
                  alt={langToggle(item.title_ua, item.title_ru, item.title_en)} 
                />
                <div className="slide-content">
                  <span className="slide-content__title hover-fade">
                    {langToggle(item.title_ua, item.title_ru, item.title_en)}
                  </span>
                  <Link
                    to={'/' + item.slug}
                    className="slide-content__link hover-fade"
                  >
                    {langToggle(
                      'Дізнатись більше',
                      'Узнать больше',
                      'Learn more'
                    )}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      {/* <div className="prev-next-controls">
        <button onClick={sliderGoToPrev}>prev</button>
        <button onClick={sliderGoToNext}>next</button>
      </div> */}
    </div>
  );
};

export default ServicesSlider;
