import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../../Hooks/useLanguage';
import ArrowLeft from '../../../../../svg/arrow_left.svg';

const SubMenu = ({ dataServices, closeSubMenu, subMenu }) => {
  const langTogle = useLanguage;

  return (
    <nav className={!subMenu ? 'sub-menu-wrapper' : 'sub-menu-wrapper is-open'}>
      <header className="main-menu--header">
        <button className="close-sub" onClick={closeSubMenu}>
          X
          <i className="close-sub--icon">
            <ArrowLeft />
          </i>
        </button>
      </header>
      <ul className={!subMenu ? 'sub-menu' : 'sub-menu is-open'}>
        {dataServices.map((item, index) => {
          return (
            <li className="sub-menu--item" key={index}>
              <Link to={'/' + item.slug} className="sub-menu--link">
                <span className="sub-menu--title">
                  {langTogle(item.title_ua, item.title_ru, item.title_en)}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SubMenu;
