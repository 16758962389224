import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../../Hooks/useLanguage';
import MobileLanguages from '../MobileLanguages/MobileLanguages';
import Logo from '../../../../../svg/logo.svg';

const MainMenu = ({ dataNav, openSubMenu, active }) => {
  const langTogle = useLanguage;

  return (
    <nav
      className={!active ? 'main-menu-wrapper' : 'main-menu-wrapper is-animate'}
    >
      <header className="main-menu--header">
        <Logo className="main-menu--logo" />
      </header>
      <ul className={!active ? 'main-menu' : 'main-menu is-animate'}>
        {dataNav.map((item, index) => {
          const count = index + 1;

          if (item.type === 'services') {
            return (
              <li
                className="main-menu--item"
                key={index}
                onClick={openSubMenu}
                role="presentation"
              >
                <span className="menu-item--wrapper">
                  <span className="menu-item--count">{'0' + count + ' /'}</span>
                  <span className="menu-item--title">
                    {langTogle(item.title_ua, item.title_ru, item.title_en)}
                  </span>
                </span>
              </li>
            );
          } else {
            return (
              <li className="main-menu--item" key={index}>
                <span className="menu-item--wrapper">
                  <Link to={item.link} className="menu-item--link">
                    <span className="menu-item--count">
                      {'0' + count + ' /'}
                    </span>
                    <span className="menu-item--title">
                      {langTogle(item.title_ua, item.title_ru, item.title_en)}
                    </span>
                  </Link>
                </span>
              </li>
            );
          }
        })}
        <li className="main-menu--item mobile-lang">
          <MobileLanguages />
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
